<template>
  <app-section class="unemploy-benefits animated fadeIn">
    <div class="title flex-initial my-32 pb-32 text-46 font-semibold text-grey-900">
      {{ $t('UNEMPLOYMENT_BENEFITS.TITLE') }}
    </div>
    <div class="description text-20 text-grey-500 pb-12">
      {{ $t('UNEMPLOYMENT_BENEFITS.DESCRIPTION') }}
    </div>

    <div class="information-field flex justify-end">
      <button class="information-field-button" @click="openModalInfo()">
        <information :text="$t('UNEMPLOYMENT_BENEFITS.INFORMATION')" class="mt-38" />
      </button>
    </div>

    <app-info-messages v-if="lastRequestStatus" :text="lastRequestStatus"></app-info-messages>

    <app-process-info
      v-if="!formsAreVisible"
      :translation-keys="translationKeys"
      :document="document"
      :fields="fields"
    ></app-process-info>

    <forms-with-steps
      v-if="formsAreVisible"
      ref="forms"
      :steps="steps"
      @reloadUnemploymentBenefitsData="loadUnemploymentBenefitsData"
    />

    <div v-if="formsAreVisible" class="flex justify-end mb-106 pr-28">
      <app-button class="text-blue-400" @click="goHome()">{{ $t('SHARED.CANCEL') }}</app-button>
      <app-button
        :disabled="!isFormValid"
        :class="isFormValid ? 'bg-blue-500' : 'bg-grey-400'"
        @click="openConfirmSave()"
        >{{ $t('SHARED.SEND_CHANGES') }}</app-button
      >
    </div>

    <div v-if="!formsAreVisible" class="flex justify-end mb-106 pr-28 mt-80">
      <app-button class="bg-blue-500" @click="goHome()">
        {{ $t('SHARED.ACCEPT') }}
      </app-button>
    </div>
  </app-section>
</template>
<script>
import AppSection from '@/components/AppSection'
import InformationModal from '@/components/modals/InformationModal'
import ConfirmSave from '@/components/modals/ConfirmSave'
import FormsWithSteps from '@/components/forms-with-steps/FormsWithSteps'
import UnemploymentBenefitsForm from './components/UnemploymentBenefitsForm'
import HomeUrl from '@/mixins/HomeUrl'
import { mapMutations } from 'vuex'
import employeeService from '@/shared/services/employeeService'
import AppProcessInfo from '@/components/AppProcessInfo'
import { DateFormatter } from '@/shared/utils/dateFormatter'

export default {
  name: 'UnemploymentBenefits',
  components: {
    AppSection,
    FormsWithSteps,
    AppProcessInfo,
    Information: () => import('@/components/Information'),
    AppButton: () => import('@/components/AppButton'),
    AppInfoMessages: () => import('@/components/AppInfoMessages'),
  },

  mixins: [HomeUrl],

  data() {
    return {
      forms: undefined,
      steps: [
        {
          title: 'UNEMPLOYMENT_BENEFITS.FORM.TITLE',
          component: UnemploymentBenefitsForm,
        },
      ],
      translationKeys: {
        infoMsg: 'UNEMPLOYMENT_BENEFITS.PROCESS_INFO.INFO',
      },
      fields: undefined,
      document: undefined,
      lastRequestStatus: undefined,
    }
  },

  computed: {
    isFormValid() {
      return this.forms && this.forms.isValid !== false
    },
    formsAreVisible() {
      return !(this.fields && this.fields.length > 0 && this.document)
    },
  },

  mounted() {
    this.forms = this.$refs.forms
    this.loadUnemploymentBenefitsData()
  },

  methods: {
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    loadUnemploymentBenefitsData() {
      this.setRequestInProgressStatus(true)
      this.fields = undefined
      this.document = undefined
      this.lastRequestStatus = undefined

      employeeService
        .getUnemploymentBenefits()
        .then((data) => {
          this.restructureData(data)
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },
    restructureData(data) {
      const isPending = data.prestacionesVO[0].estadoSolicitud === '0'
      const isRejected = data.prestacionesVO[0].estadoSolicitud === '2'
      const isAccepted = data.prestacionesVO[0].estadoSolicitud === '3'
      if (isPending) {
        this.document = {
          name: data.prestacionesVO[0].ficherosVO[0].nombreFichero,
          data: data.prestacionesVO[0].ficherosVO[0].ficheroB64,
        }
        this.fields = [
          {
            label: 'UNEMPLOYMENT_BENEFITS.PROCESS_INFO.REQUEST_REASON',
            description: data.prestacionesVO[0].motivoSolicitud.replace(/!N!/g, '\n'), // product layer transforms line break into !N!
          },
        ]
      } else {
        const date = DateFormatter.formatDateInSpanish(
          new Date(data.prestacionesVO[0].fechaModificacion)
        )
        if (isRejected) {
          this.lastRequestStatus = this.$t('UNEMPLOYMENT_BENEFITS.REQUEST_STATUS.REJECTED', {
            date,
          })
        } else if (isAccepted) {
          this.lastRequestStatus = this.$t('UNEMPLOYMENT_BENEFITS.REQUEST_STATUS.ACCEPTED', {
            date,
          })
        }
      }
    },
    openModalInfo() {
      this.$modal.open(
        InformationModal,
        {
          translationKeys: {
            title: 'UNEMPLOYMENT_BENEFITS.MODAL.TITLE',
            paragraphs: [
              'UNEMPLOYMENT_BENEFITS.MODAL.P1',
              'UNEMPLOYMENT_BENEFITS.MODAL.P2',
              'UNEMPLOYMENT_BENEFITS.MODAL.P3',
              'UNEMPLOYMENT_BENEFITS.MODAL.P4',
              'UNEMPLOYMENT_BENEFITS.MODAL.P5',
            ],
          },
          email: 'sae.es@sae.bbva.com',
        },
        {
          closeOnClickMask: true,
          maskClass: 'bg-mask',
        }
      )
    },
    openConfirmSave() {
      const options = {
        closeOnClickMask: false,
        maskClass: 'bg-mask',
      }
      this.$modal.open(ConfirmSave, {}, options).then((response) => {
        if (!response.confirm) {
          return
        }

        this.forms.save()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.information-field {
  margin-bottom: 64px;
}
</style>
