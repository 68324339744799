<template>
  <div class="unemployment-benefits-form flex flex-col w-full">
    <form class="form mt-33 flex-auto" @submit.prevent>
      <!-- ATTACH FILE -->
      <div class="attach-file-field form-row-wrapper md:flex mb-40">
        <div class="flex flex-auto w-1/3 ml-68">
          <label class="text-16 text-gray-900">
            {{ $t('FILE_UPLOADER.LABEL') }}
          </label>
          <div>
            <app-tooltip
              class="ml-45 cursor-pointer"
              :translation-keys="tooltipProps.translationKeys"
              :placement="tooltipProps.placement"
              :offset="tooltipProps.offset"
              :delay="tooltipProps.delay"
            ></app-tooltip>
          </div>
        </div>

        <div class="w-1/3 max-w-md mr-28 mt-3">
          <app-file-uploader
            ref="fileUploader"
            :text="$t('FILE_UPLOADER.PLACEHOLDER')"
            @fileSelected="setFile"
          />
        </div>
      </div>

      <!-- REQUEST REASON -->
      <div class="request-reason-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('UNEMPLOYMENT_BENEFITS.FORM.FIELDS.REQUEST_REASON.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="requestReason"
            type="textarea"
            :maxlength="maxLength"
            :error="$v.requestReason.$invalid && $v.requestReason.$dirty"
            :placeholder="$t('UNEMPLOYMENT_BENEFITS.FORM.FIELDS.REQUEST_REASON.PLACEHOLDER')"
            @input="$v.requestReason.$touch()"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import employeeService from '@/shared/services/employeeService'
import { mapMutations } from 'vuex'
import Alert from '@/components/modals/Alert'

export default {
  name: 'UnemploymentBenefitsForm',

  components: {
    AppInput: () => import('@/components/form/AppInput'),
    AppFileUploader: () => import('@/components/form/AppFileUploader'),
    AppTooltip: () => import('@/components/AppTooltip'),
  },

  mixins: [validationMixin],

  data: () => ({
    tooltipProps: {
      translationKeys: { tooltipMsg: 'TOOLTIP.EXTENSIONS' },
      placement: 'bottom-center',
      offset: 20,
      delay: {
        show: 50,
        hide: 800,
      },
    },
    files: [],
    requestReason: undefined,
    maxLength: 254,
  }),

  computed: {
    isValid() {
      return !this.$v.$invalid
    },
  },

  methods: {
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    reset() {
      this.requestReason = ''
      this.files = []
      this.$refs.fileUploader.deleteFile()
      this.$v.$reset()
    },
    setFile(file) {
      this.files = file ? [file] : []
    },
    save() {
      if (!this.isValid) return
      this.setRequestInProgressStatus(true)

      employeeService
        .postUnemploymentBenefits(this.requestReason, this.files)
        .then((response) => {
          if (response.estado === 'OK') {
            this.$parent.$emit('reloadUnemploymentBenefitsData')
            this.openUpdateSuccessModal()
            this.reset()
          } else if (response.estado === 'KO') {
            this.openUpdateErrorModal(response.literalMensaje)
          }
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },
    openUpdateSuccessModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.SUCCESS_UPDATE_DATA.TITLE',
          text: 'MODALS.SUCCESS_UPDATE_DATA.TEXT',
        },
      }

      this.$modal.open(Alert, props, { maskClass: 'bg-mask' })
    },
    openUpdateErrorModal(text = 'MODALS.ERROR_UPDATE_DATA.TEXT') {
      const props = {
        translationKeys: {
          title: 'MODALS.ERROR_UPDATE_DATA.TITLE',
          text,
        },
      }

      this.$modal.open(Alert, props, { maskClass: 'bg-mask' })
    },
    maxLengthValidator(value) {
      const lineBreaks = (value.match(/\n/g) || []).length
      const length = value.length - lineBreaks + lineBreaks * 3 // product layer transforms line break into !N!

      return length <= this.maxLength
    },
  },

  validations() {
    return {
      requestReason: {
        required,
        maxLength: this.maxLengthValidator,
      },
      files: {
        required,
      },
    }
  },
}
</script>
